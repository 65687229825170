class FirebaseFCM {
    constructor() {
        this.messaging = null;
        this.current_token = localStorage.getItem("firebase_token") ?? null;
        // this.token_save_route = data.token_save_route;

        //Initialize Firebase
        this.initialize();
    }

    /**
     * Initialize Firebase
     * @returns {boolean}
     */
    initialize() {
        _this = this;

        if (process.env.MIX_FIREBASE_WEB_ENABLED !== "true") {
            console.log("Firebase web notifications are disabled");
            return false;
        }

        var firebaseConfig = {
            apiKey: process.env.MIX_FIREBASE_WEB_API_KEY,
            authDomain: process.env.MIX_FIREBASE_WEB_AUTH_DOMAIN,
            databaseURL: process.env.MIX_FIREBASE_WEB_DATABASE_URL,
            projectId: process.env.MIX_FIREBASE_PROJECT_ID,
            storageBucket: process.env.MIX_FIREBASE_WEB_STORAGE_BUCKET,
            messagingSenderId: process.env.MIX_FIREBASE_WEB_MESSAGING_SENDER_ID,
            appId: process.env.MIX_FIREBASE_WEB_APP_ID,
            measurementId: process.env.MIX_FIREBASE_WEB_MEASUREMENT_ID,
        };

        // Initialize Firebase
        firebase.initializeApp(firebaseConfig);

        // Retrieve Firebase Messaging object.
        _this.messaging = firebase.messaging();
        // Add the public key generated from the console here.
        if (process.env.MIX_FIREBASE_WEB_PUBLIC_VAPID_KEY.length != 0) {
            _this.messaging.usePublicVapidKey(
                process.env.MIX_FIREBASE_WEB_PUBLIC_VAPID_KEY
            );
        }
    }

    /**
     * This funcion will make sure that token is provied by firebase and synced with
     * the server
     * @param data
     */
    ensureToken(data) {
        _this = this;
        // Request Browser permissions to receive notifications
        _this.messaging
            .requestPermission()
            .then(function () {
                console.log("Notification permission granted.");
                // [START refresh_token]
                // Callback fired if Instance ID token is updated.
                _this.messaging.onTokenRefresh(() => {
                    _this.messaging
                        .getToken()
                        .then((refreshedToken) => {
                            // Indicate that the new Instance ID token has not yet been sent to the
                            // app server.
                            //REMOVE OLD TOKEN FROM STORAGE
                            _this.unsetToken();
                            // Send Instance ID token to app server.
                            _this.sendTokenToServer(
                                refreshedToken,
                                data.token_save_route
                            );
                        })
                        .catch((err) => {
                            console.log(
                                "Unable to retrieve refreshed token ",
                                err
                            );
                            showToken(
                                "Unable to retrieve refreshed token ",
                                er
                            );
                        });
                });
                // [END refresh_token]

                //Get Token
                _this.messaging
                    .getToken()
                    .then((newToken) => {
                        if (newToken) {
                            console.log(newToken);
                            if (newToken != _this.current_token) {
                                console.log("Token is new");
                                _this.sendTokenToServer(
                                    newToken,
                                    data.token_save_route
                                );
                            } else {
                                console.log("Token is same as old one");
                            }
                        } else {
                            // Show permission request.
                            console.log(
                                "No Instance ID token available. Request permission to generate one."
                            );
                            //REMOTE TOKEN FROM STORAGE
                            _this.unsetToken();
                        }
                    })
                    .catch((err) => {
                        console.log(
                            "An error occurred while retrieving token. ",
                            err
                        );
                        //REMOTE TOKEN FROM STORAGE
                        _this.unsetToken();
                    });
            })
            .catch(function (err) {
                _this.messaging = null;
                console.log("Unable to get permission to notify.", err);
            });

        this.listenNotifications();
    }

    /**
     * Set notification urls
     * @param urls
     */
    setNotificationUrls(urls) {
        this.urls = urls;
    }

    /**
     * Generate notification url
     * @param notification
     * @returns {string}
     */
    generateNotifUrl(notification) {
        let url = null;
        if (
            this.urls[notification.model] !== undefined &&
            this.urls[notification.model] !== null &&
            this.urls[notification.model].length !== 0
        ) {
            url = this.urls[notification.model];
            url = url.replace("model_id", notification.id);
        }

        return url;
    }

    /**
     * Listen for notifications
     */
    listenNotifications() {
        //On Background
        navigator.serviceWorker.addEventListener("message", (event) => {
            let payload = event.data;
            // eslint-disable-next-line no-unused-vars
            let data = payload.data;
            let notification = payload.notification;

            //Display notification
            new NotificationMsg(
                "success",
                notification.title,
                payload.notification.body,
                this.generateNotifUrl(data),
                10000
            );

            //Check notification type
            if (payload.messageType === "push-received") {
                //Notification received while tab is active
                //Display Notification in browser
                let noteTitle = notification.title;
                let noteOptions = {
                    body: notification.body,
                    icon: notification.image,
                };
                /** This Notifications API interface is used to configure and display desktop notifications to the user. */
                new Notification(noteTitle, noteOptions);
            } else {
                //Notification clicked
            }
        });
    }

    /**
     * Sometimes laravel will kill the session but the token is still saved
     * This function will kill the token if exists , and when the users logs in again
     * a new token will be provided
     */
    clearDummyToken() {
        console.log("current_token: " + this.current_token);
        if (this.current_token !== null) {
            //Delete this token
            console.log("This token should be deleted");
            this.deleteToken(function (token) {
                console.log("Dummy token deleted: " + token);
            });
        }
    }

    /**
     * Send the Instance ID token your application server, so that it can:
     * - send messages back to this app
     * - subscribe/unsubscribe the token from topics
     * @param currentToken
     * @param token_save_route
     */
    sendTokenToServer(currentToken, token_save_route) {
        _this = this;
        //Check if token in storage is null
        console.log("Check if null : " + _this.current_token);
        if (_this.current_token === null) {
            console.log("Sending token to server..." + currentToken);
            //Send token to server
            axios
                .post(token_save_route, {
                    token: currentToken,
                })
                .then((response) => {
                    // SET TOKEN
                    _this.setToken(currentToken);
                    console.log("Token has been saved to the server");
                })
                .catch((error) => {
                    let error_code = error.response.data.errors[0]["code"];
                    if (error_code === "global.already_exists:firebaseToken") {
                        // SET TOKEN
                        console.log("Token already exists, so mark as sent");
                        _this.setToken(currentToken);
                    }
                });
        } else {
            console.log(
                "Token already sent to server so won't send it again " +
                    "unless it changes: " +
                    currentToken
            );
        }
    }

    /**
     * Save token to storage
     * @param token
     */
    setToken(token) {
        console.log("------ Setting Token");
        window.localStorage.setItem("firebase_token", token);
    }

    /**
     * Remove token from storage
     */
    unsetToken() {
        console.log("------ Unsetting Token");
        window.localStorage.removeItem("firebase_token");
    }

    /**
     * Delete token from firebase
     * @param callback
     */
    deleteToken(callback) {
        _this = this;
        if (process.env.MIX_FIREBASE_WEB_ENABLED != "true") {
            callback(null);
        }

        if (this.messaging != null) {
            // Delete Instance ID token.
            // [START delete_token]
            this.messaging
                .getToken()
                .then((currentToken) => {
                    this.messaging
                        .deleteToken(currentToken)
                        .then((boolean) => {
                            console.log("Token deleted.");
                            _this.unsetToken();
                            callback(currentToken);
                        })
                        .catch((err) => {
                            console.log("Unable to delete token. ", err);
                        });
                    // [END delete_token]
                })
                .catch((err) => {
                    console.log("Error retrieving Instance ID token. ", err);
                });
        } else {
            callback(null);
        }
    }
}

module.exports = FirebaseFCM;
