class Authentication {
    logout(logout_route, login_route) {
        FirebaseFCM.deleteToken((token) => {
            axios
                .post(logout_route, {
                    firebase_token: token,
                })
                .then((response) => {

                })
                .catch((error) => {
                    console.log(error);
                }).finally(() => {
                    window.location.replace(login_route);
                });
        });
    }
}

module.exports = Authentication;
