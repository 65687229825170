class NotificationMsg {
    constructor(type, title, message, url = null, timeout = 10000) {
        this.type = type;
        this.title = title;
        this.message = message;
        this.url = url;
        this.timeout = timeout;

        this.show();
    }

    build() {
        //If message is array => convert it to lines
        if (_.isArray(this.message)) {
            let newContent = "";
            for (line in this.message) {
                let error_is = errors[error_key];
                newContent += error_is + "<br />";
            }

            this.message = newContent;
        }

        console.log("URL: " + this.url);

        let url_open = "";
        let url_close = "";
        if (this.url != null) {
            url_open = "<a href='" + this.url + "'>";
        }
        if (this.url != null) {
            url_close = "</a>";
        }

        return `
      <div class="notification alert alert-${this.type}" role="alert">
        ${url_open}
        <p class='mb-0'><b>${this.title}</b></p>
        <hr class='my-2'>

				${this.message}
		${url_close}
         <button type="button" class="close" aria-label="Close" onclick="$(this).parents('.notification').hide()">
            <span aria-hidden="true">&times;</span>
        </button>
			</div>`;
    }

    show() {
        let $notifications = $("#notifications");
        let $notification = this.build();

        let $newNotification = $($notification).appendTo($notifications);

        if (this.timeout != false) {
            //Auto Hide in a bit
            setTimeout(() => {
                $newNotification.addClass("d-none").text("");
            }, this.timeout);
        }
    }
}

module.exports = NotificationMsg;
